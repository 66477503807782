
/**
 * 2023-05-31 John Yee
 * This component requires the parent to import mixins/fieldLabelAndTooltip.js.
 * toggleIsToolTipOpen and isToolTipOpen are declared in the mixin.
 */
export default {
  props: {
    fieldLabel: {
      type: String,
      default: '',
      required: false
    },
    fieldName: {
      type: String,
      default: '',
      required: false
    },
    instructions: {
      type: String,
      default: '',
      required: false
    },
    isToolTipOpen: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    toolTipTitle: {
      type: String,
      default: '',
      required: false
    },
    toolTipContent: {
      type: String,
      default: '',
      required: false
    },
  },
  computed: {
    isToolTipContent () {
      return !!this.toolTipContent.trim()
    },
  }
}
