import { render, staticRenderFns } from "./FormJurisdictionInput.vue?vue&type=template&id=da1f6df2&scoped=true"
import script from "./FormJurisdictionInput.vue?vue&type=script&lang=js"
export * from "./FormJurisdictionInput.vue?vue&type=script&lang=js"
import style0 from "./FormJurisdictionInput.vue?vue&type=style&index=0&id=da1f6df2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da1f6df2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FieldLabelAndTooltip: require('/opt/build/repo/components/FieldLabelAndTooltip.vue').default,At: require('/opt/build/repo/components/At.js').default})
